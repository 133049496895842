.selection-popup {
  padding: 3em 0;
}

.selection-popup__head {
  border-bottom: 3px solid #dcdcdc;
  padding: 0 3em;
}

.selection-popup__title {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.selection-popup__title-icon {
  margin-right: 1em;
}

.selection-popup__progress-bar {
  margin-bottom: 2em;
}

.selection-popup__controls {
  display: flex;
  justify-content: space-between;
  padding: 0 3em;
}

.selection-popup__slide {
  width: 100%;
  padding: 3em 3em 1em;
}

.selection-popup__slide_last-slide {
  padding-top: 1em;
}

.selection-popup__form-inner {
  max-width: 300px;
}

.selection-popup__slide-title {
  margin-bottom: 1em;
}

.selection-popup__notice {
  display: flex;
  align-items: center;
  background: #f0f0f0;
  padding: 0.6em;
  border-radius: 10px;
  margin-bottom: 1em;
}

.selection-popup__notice-icon {
  margin-right: 0.6em;
}

.selection-popup__grid-checkbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1em;
}

.selection-popup__slide:focus {
  outline: none;
}

.selection-popup__slide-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selection-popup__icon-success {
  margin-bottom: 1em;
}

.selection-popup__text-success {
  max-width: 280px;
}

.selection-popup__controls .button:not(.js-selection-slider__prev) {
  display: none;
}

.selection-popup__controls .button.slick-disabled {
  visibility: hidden;
}

.selection-popup__controls .button.active {
  display: block;
}

@media screen and (max-width: 1169px) {
  .selection-popup {
    font-size: 15px;
  }
}

@media screen and (max-width: 959px) {
  .selection-popup {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .selection-popup {
    font-size: 13px;
    padding: 1em 0;
  }

  .selection-popup__grid-checkbox {
    grid-template-columns: 1fr;
  }

  .selection-popup__head {
    padding: 0 1em;
  }

  .selection-popup__slide {
    padding: 1em 2em;
  }
}
