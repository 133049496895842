.callback-popup {
  display: none;
  padding: 3em 0;
}

.callback-popup.active {
  display: block;
}

.callback-popup__head {
  padding: 0 3em;
  margin-bottom: 2em;
  text-align: center;
  display: flex;
  justify-content: center;
}

.callback-popup__body {
  padding: 0 3em;
  display: flex;
  justify-content: center;
}

.callback-popup__form-inner {
  max-width: 300px;
}

.callback-popup__controls {
  padding: 1em 3em;
  display: flex;
  justify-content: center;
}

.callback-popup__success {
  display: none;
  padding: 3em 0;
}

.callback-popup__success.active {
  display: block;
}

@media screen and (max-width: 1169px) {
  .callback-popup {
    font-size: 15px;
  }
}

@media screen and (max-width: 959px) {
  .callback-popup {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .callback-popup {
    font-size: 13px;
  }

  .selection-popup__slide {
    padding: 1em 2em;
  }
}
