.button {
  display: block;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 10em;
  text-decoration: none;
}

.button:focus {
  outline: none;
}

.button_yellow {
  background: var(--yellow-lvl-400);
  color: var(--gray-lvl-900);
  font-weight: 700;
  display: flex;
}

.button_gray {
  background: var(--gray-lvl-400);
  color: var(--gray-lvl-800);
  font-weight: 600;
}

.button_gray:hover {
  background: var(--gray-lvl-450);
}

.button_yellow:hover {
  background: var(--yellow-lvl-500);
}

.button_wide {
  padding: 1em 3em;
}

.button_medium {
  padding: 1em 2em;
}

.button_small {
  padding: 0.5em 1em;
}

.button_pulse {
  animation: scaling 1s infinite;
}

@keyframes scaling {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(1.03);
  }

  to {
    transform: scale(1);
  }
}
