.link {
  font-size: 1em;
}

.link:focus {
  outline: none;
}

.link_decor-none {
  text-decoration: none;
}

.link_bold {
  font-weight: 700;
}

.link_white {
  color: #fff;
  transition: 0.2s;
}

.link_white:hover {
  color: var(--yellow-lvl-400);
}

.link_yellow {
  color: var(--yellow-lvl-400);
}

.link_big {
  font-size: 1.5em;
}

.link_nowrap {
  white-space: nowrap;
}
