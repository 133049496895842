.text {
  font-size: 1em;
}

.text_bold {
  font-weight: 700;
}

.text_light {
  font-weight: 300;
}

.text_center {
  display: inline-block;
  text-align: center;
}

.text_header-banner {
  display: block;
  max-width: 300px;
  font-size: 1.25em;
}

.text_underline {
  text-decoration: underline;
}

.text_white {
  color: #fff;
}

.text_uppercase {
  text-transform: uppercase;
}
