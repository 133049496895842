.title {
  margin: 0;
}

.title_margin-bottom {
  margin-bottom: 1em;
}

.title_h1 {
  font-size: 2em;
}

.title_h2 {
  font-size: 1.5em;
}

.title_center {
  text-align: center;
}

.title_yellow {
  color: var(--yellow-lvl-400);
}
