.progress-bar {
  display: grid;
  grid-template-columns: 1fr minmax(41px, auto);
  grid-column-gap: 1em;
  align-items: center;
}

.progress-bar__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  grid-column-gap: 0.3125em;
}

.progress-bar__item {
  height: 0.3125em;
  background: var(--yellow-lvl-400);
  border-radius: 1em;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.progress-bar__item::before {
  content: "";
  position: absolute;
  right: 0;
  background: var(--gray-lvl-400);
  height: 100%;
  width: 0;
  transition: 0.5s;
}

.progress-bar__item:hover::before {
  background: var(--gray-lvl-500);
}

.progress-bar__item.active::before {
  width: 0;
}

.progress-bar__item.active ~ .progress-bar__item::before {
  width: 100%;
}
