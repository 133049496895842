.input-text {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1em;
}

.input-text__input {
  display: block;
  background: url('../img/svg/checkbox-done.svg') 110% center no-repeat #fff;
  background-size: 1.5em;
  border: 2px solid var(--yellow-lvl-400);
  border-radius: 0.25em;
  color: #000;
  font-size: 1em;
  padding: 0.625em 2.5em 0.625em 0.625em;
  transition: background-position 0.2s;
}

.input-text__input:hover {
  border-color: var(--yellow-lvl-600);
}

.input-text__input_required + .input-text__label::after {
  content: "*";
  color: var(--red-lvl-400);
}

.input-text__label {
  position: relative;
  margin-bottom: 0.625em;
  cursor: pointer;
}

.input-text__input_required + .input-text__label::before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50%;
  width: 161px;
  height: 36px;
  display: none;
  color: white;
  background: url('../img/svg/required-error.svg') center no-repeat;
  background-size: cover;
  padding: 5px;
  border-radius: 5px;
}

.input-text__input_required.js-required-error + .input-text__label::before {
  display: block;
}

.input-text__input:focus {
  outline: none;
  background-color: #fff;
}

.input-text__input:not(:focus):not(:placeholder-shown):valid {
  background: url('../img/svg/checkbox-done.svg') 97% center no-repeat #fff;
  background-size: 1.5em;
}

.input-text__info {
  font-size: 0.9em;
  color: var(--gray-lvl-700);
  margin-top: 0.5em;
}

@media screen and (max-width: 767px) {
  .input-text__input_required + .input-text__label::before {
    width: 132px;
    height: 29px;
  }
}
