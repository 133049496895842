.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5em 0;
  position: relative;
}

.checkbox__input {
  overflow: hidden;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.checkbox__control {
  display: block;
  border-radius: 0.25em;
  min-width: 1.5em;
  min-height: 1.5em;
  width: 1.5em;
  height: 1.5em;
  background: var(--gray-lvl-400);
  margin-right: 0.8em;
  border: 2px solid transparent;
  box-sizing: border-box;
}

.checkbox__input:checked + .checkbox__control {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>') center no-repeat var(--yellow-lvl-400);
}

.checkbox:hover > .checkbox__input:not(:checked) + .checkbox__control {
  border-color: var(--gray-lvl-700);
}

.checkbox:hover > .checkbox__input:checked + .checkbox__control {
  background-color: var(--yellow-lvl-500);
}

.checkbox__label {
  user-select: none;
}
