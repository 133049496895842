.fancybox-content.popup {
  max-width: 700px;
  padding: 0;
}

.popup__scroll-block {
  overflow: scroll;
  padding: 2em;
  max-height: 80vh;
}

@media screen and (max-width: 767px) {
  .fancybox-content.popup {
    max-width: 308px;
  }
}

.loading::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../img/svg/preloader.svg') center no-repeat, rgba(0, 0, 0, 0.5);
}
