.header {
  position: absolute;
  width: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  padding: 25px;
  z-index: 2;
}

.header__logo {
  grid-column: 1 / 2;
}

.header__description {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.header__contacts {
  grid-column: 3 / 4;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: flex-end;
}

.header__callback-btn {
  grid-area: 2 / 3 / 3 / 4;
  justify-self: flex-end;
}

@media screen and (max-width: 1169px) {
  .header {
    grid-template-columns: 150px 1fr 200px;
    font-size: 14px;
  }
}

@media screen and (max-width: 959px) {
  .header {
    grid-template-columns: 125px 1fr 200px;
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .header__description {
    display: none;
  }

  .header {
    grid-template-columns: minmax(100px, 150px) 1fr;
    grid-row-gap: 0.5em;
    font-size: 14px;
    padding: 15px;
  }

  .header__contacts {
    grid-column: 2 / 3;
  }

  .header__callback-btn {
    grid-area: 2 / 2 / 3 / 3;
  }
}
