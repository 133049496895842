/* Переменные CSS */

:root {
  --yellow-lvl-400: #fdbf00;
  --yellow-lvl-500: #fdae00;
  --yellow-lvl-600: #fd9600;
  --gray-lvl-400: #dcdcdc; /* 220 */
  --gray-lvl-450: #cdcdcd; /* 205 */
  --gray-lvl-500: #bbb; /* 187 */
  --gray-lvl-700: #959595; /* 149 */
  --gray-lvl-800: #636363; /* 99 */
  --gray-lvl-900: #2a2a2a; /* 42 */
  --red-lvl-400: #f00;
  --brown-lvl-400: #6f4a00;
}

/* Другие главные стили */
html {
  /* Cглаживание шрифтов на MAC OSX - ВНИМАНИЕ!!! Делают шрифт немного тоньше!!! */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  color: #000;
  background: #fff;
  font-family: "Ubuntu", sans-serif;
}

/* Настройка враппера */

.wrapper {
  margin: 0 auto;
  background: #fff;
}

/* Настройки брейкпоинтов враппера */

@media screen and (min-width: 1170px) {
  .wrapper {
    width: 1170px;
  }
}

@media screen and (max-width: 1169px) {
  .wrapper {
    width: 960px;
  }
}

@media screen and (max-width: 959px) {
  .wrapper {
    width: 768px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
  }
}

/* Скрытие блока в определенном диапазоне разрешений */

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 959px) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: 960px) and (max-width: 1169px) {
  .hidden-md {
    display: none !important;
  }
}

@media screen and (min-width: 1170px) {
  .hidden-lg {
    display: none !important;
  }
}

/* Скрытие блока в десктопной или мобильной версии */

@media screen and (min-width: 768px) {
  .hidden-desktop {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}
